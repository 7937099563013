* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;

  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.btn {
  margin: 1rem 1rem 0 0;
  padding: 0.25rem 0.5rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: 1px solid #808080;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.btn:hover {
  background-color: #e4e3e34d;
}

#page {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  overflow: hidden;
}

/* CSS FOR A DEVICE WITH LESS THAN 800px WIDTH */
@media screen and (min-width: 800px) {
  #page {
    font-size: 3rem;
  }

  .btn {
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
  }
}

.app__brand__text {
  font-size: 2rem;
  font-weight: 800;
  margin-left: 0.5rem;
}

/* Sidebar toggle button starts */
.outer__circle {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #5f97ef;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer__circle::after {
  position: absolute;
  top: 0.225rem;
  left: 0.215rem;
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  background-color: #fff;
}

.inner__circle {
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #5f97ef;
  z-index: 100;
}

.inner__circle::after {
  position: absolute;
  top: 0.125rem;
  left: 0.15rem;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
}
/* Sidebar toggle button ends */
